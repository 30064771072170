<template>
  <div v-if="this.user.employee_settings.bonusprogram" class="bonus-quickview-container">
      <div class="loader" v-if="isLoaderVisible">
          <div class="loaderImg"></div>
      </div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
            <div class="card-header-text" v-if="!isEditModeOn">
                <h3 v-if="user.id == this.userId">Deine Bonus Quickview <span v-if="bonusData">({{ bonusData.requestedData.year}}/Q{{ bonusData.requestedData.quarter}})</span></h3>
                <h3 v-if="user.id != this.userId">{{ user.detail.first_name }} {{ user.detail.last_name }}</h3>
                <p>Diese Statistik wird gecacht und für mehrere Stunden gespeichert.</p>
                <vs-icon icon="edit" @click="isEditModeOn = true" v-if="allowChangeMode" class="edit-mode-icon"></vs-icon>
            </div>
            <div class="card-header-edit-mode" v-if="isEditModeOn">
                <select v-model="selectedYear" style="background-color:lightgrey" class="mr-4">
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                </select>
                <label>Quartal:</label>
                <select v-model="selectedQuarter" style="background-color:lightgrey">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                </select>
                <vs-button color="dark" type="filled" icon="search" size="small" @click="fetchData()">Auswählen</vs-button>
                <vs-icon icon="close" @click="isEditModeOn = false" class="edit-mode-icon"></vs-icon>
            </div>



          <vs-divider/>


          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <div id="points-reached">
                <vue-apex-charts  type="radialBar" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <div id="time-passed-chart">
                <vue-apex-charts  type="radialBar" height="350" :options="timePassedchartOptions" :series="timePassedSeries"></vue-apex-charts>
              </div>
            </vs-col>
          </vs-row>

          <div>
            <b>Aktueller Punktestand:</b> <span v-if="bonusData.bonus">{{ bonusData.bonus.current|currency }} Pkt.</span><br>
            <b>Ziel:</b> <span v-if="bonusData.bonus">{{ bonusData.bonus.target|currency }} Pkt.</span><br>
           <br>
            <b class="d-block">Nach Monaten (Summe)</b>
            <span v-if="bonusData.log.monthlyPointsSum" v-for="(sum, month) in bonusData.log.monthlyPointsSum" class="d-block">Monat {{month}}: {{sum|currency}} Pkt.</span>
            <br>
            <b class="d-block">Nach Typen (Summe)</b>
            <span v-if="bonusData.log.quarterPointsTypes" v-for="(sum, typName) in bonusData.log.quarterPointsTypes" class="d-block">{{typName}}: {{sum|currency}} Pkt.</span>
          </div>


        </vs-card>
      </vs-col>
    </vs-row>



  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep.js";
import ApiService from "@/api/index.js";
import VueApexCharts from 'vue-apexcharts'
import {integer} from "vee-validate/dist/rules.esm";

export default {
  name: "PresentUserBonusQuickview",
  components: {
    VueApexCharts
  },
  props: {
      user: {
          type: Object,
          required: true,
      },
      year: {
          type: integer,
          required: false,
      },
      quarter: {
          type: integer,
          required: false,
      },
      allowChangeMode: {
          type: Boolean,
          required: false
      }
  },
  data() {
    return {
      isEditModeOn: false,
      selectedYear: null,
      selectedQuarter: null,

      isLoaderVisible: false,
      bonusData: [],
      series: [0],
      timePassedSeries: [0],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar',
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: "#e24733",
                offsetY: 120
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                }
              }
            }
          }
        },
        fill: {
          colors: ['#e24733']
        },
        stroke: {
          dashArray: 4
        },
        labels: ['erreichte Punkte'],
      },
      timePassedchartOptions: {
        chart: {
          height: 350,
          type: 'radialBar',
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: "#e24733",
                offsetY: 120
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                }
              }
            }
          }
        },
        fill: {
          colors: ['#e24733']
        },
        stroke: {
          dashArray: 4
        },
        labels: ['Zeit'],
      },

    }
  },

  computed: {
      ...mapGetters([ 'userId']),
    isPaused() {
      if (!this.getDateForToday) {
        return true;
      }

      const dateEntry = this.getDateForToday;

      if (!dateEntry.entries) {
        return true;
      }

      return (dateEntry.entries.length % 2) === 0;
    },
    getDateForToday() {
      return moment().format('YYYY-MM-DD')
    },
  },

  created() {
    this.fetchData();

  },

  mounted() {
  },

  methods: {
    fetchData() {
        this.isLoaderVisible = true;

      let year = null;
      let quarter = null;

      if (this.selectedYear){
          year = this.selectedYear
      } else if(this.year) {
          year = this.year
      }

      if (this.selectedQuarter){
          quarter = this.selectedQuarter
      } else if(this.quarter) {
          quarter = this.quarter
      }

      if(year && quarter){
          ApiService.get(`user-bonus-target/`+ this.user.id +`/run-current-bonus-calculation/`+ quarter +`/`+ year).then(response => {
              this.isLoaderVisible = false;
              if (response.data.result) {
                  this.bonusData = response.data.result
                  this.series = [response.data.result.bonus.percent]
                  this.timePassedSeries = [response.data.result.timespend.percent]
              }
          });
      } else {
          ApiService.get(`user-bonus-target/`+ this.user.id +`/run-current-bonus-calculation`).then(response => {
              this.isLoaderVisible = false;
              if (response.data.result) {
                  this.bonusData = response.data.result
                  this.series = [response.data.result.bonus.percent]
                  this.timePassedSeries = [response.data.result.timespend.percent]
              }
          });
      }
    },

  },

  filters: {
  }
}
</script>

<style scoped>
.bonus-quickview-container {
    position: relative;
}
.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    opacity: 0.7;
    z-index: 9;
}

.loaderImg {
    border: 9px solid #eee;
    border-top: 9px solid #00ADCE;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

.edit-mode-icon {
    color: #cdcdcd;
    font-size: 21px;
    position: absolute;
    right: 20px;
    top: 24px;
}

.edit-mode-icon:hover {
    color: #ecaaa2;
    cursor: pointer;
}
</style>